import { useStore } from '../../../../store'
import styled from 'styled-components'
import { PAGE_VIEWS } from '../index'
import { observer } from 'mobx-react-lite'

const WarningBanner = styled.div`
  width: 100%;
  color: var(--ELK-Black);
  text-align: center;
  padding: 1rem 0;
  background: #FFBCBC;
  font-size: 1.4rem;
`;

const BannerLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
`

const SubscriptionBanner = ({ setPageView }) => (
<WarningBanner>
    Looks like you don't have an active subscription and therefore the experience is limited. Get your subscription going
    {' '}
    <BannerLink onClick={() => setPageView(PAGE_VIEWS.SUBSCRIPTION)}>
      here
    </BannerLink>
    .
  </WarningBanner>
)

// const MaintenanceModeBanner = () => {
//   const store = useStore()
//   return (
//     <WarningBanner>
//       <>
//         Elk LIVE is experiencing technical issues that affect some users. If you cannot connect, please follow the steps in{' '}
//         <BannerLink href="https://intercom.help/elk-audio/en/collections/3294509-how-to-change-your-dns-settings" target="_blank">this article</BannerLink>
//         {' '}or you can send us a message{' '}
//         <BannerLink onClick={() => { showMaintenanceModeDialog({ store }) }}>
//           here.
//         </BannerLink>
//       </>
//     </WarningBanner>
//   )
// }

const MaintenaceScheduleBanner = () => {
    return (
      <WarningBanner>
        <>
          Heads up! On Thursday between 3-5 am Eastern Time, we’ll be running some maintenance to the system and during that time you won’t be able to log in and jam. Sorry for any inconvenience!
        </>
      </WarningBanner>
    )
}

export const Banners = observer(({ setPageView }) => {
  const store = useStore()
  return (
    <>
      {!store.userHasActiveSubscription && store.subscriptionsEnabled && <SubscriptionBanner setPageView={setPageView} />}
      {store.userHasActiveSubscription && store.isMaintenanceScheduled() && <MaintenaceScheduleBanner />}
    </>
  )
})