import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { isBetaMode, isDesktop } from '../../../../utils/utils'
import BetaOnboarding from '../banners/beta-onboarding'
import { AddFriendCard, FriendsList, Subtitle , EmptyFriendsContainer, FriendsStyledH2} from '../styles'
import { Card } from '../../../cards/card'
import { Box, H2 } from '../../../ui/primitives'
import { AddFriendIcon } from '../../../ui/icons'
import { showAddFriendsDialog } from '../../../dialogs/add-friends'
import { Spinner } from '../../../ui/spinner'
import { LoadMoreButton } from '../../../dialogs/AddPartnerToSession/styles'

const FriendsView = observer(() => {
  const store = useStore()
  const sortedFriends = store.getSortedFriends()
  const [loadingNonFriends, setLoadingNonFriends] = useState(false)

  const LoadMoreNonFriends = async () => {
    setLoadingNonFriends(true)
    store.friendsQueryOptions.offset+=15
    await store.getNonFriends(store.friendsQueryOptions.offset)
    setLoadingNonFriends(false)
  }

  return (
    <>
      {isDesktop() && isBetaMode() &&
        (
          <BetaOnboarding />
        )}
      <FriendsList>
        {sortedFriends.length > 0 &&  <FriendsStyledH2>the friends</FriendsStyledH2>}
        {sortedFriends.map((user) => {
          if (
            user.status === 'Confirmed' ||
            user.status === 'Requested' ||
            user.status === 'Pending'
          ) {
            return <Card key={user.id} user={{ ...store.users.get(user.id), id: user.id }} type="friend" />
          } else {
            return false
          }
        })}
        <AddFriendCard onClick={() => {showAddFriendsDialog({ store })}}>
          <AddFriendIcon width={80} height={80} />
          <H2>Add friends</H2>
        </AddFriendCard>

        {sortedFriends.length === 0 && (
          <EmptyFriendsContainer>
            <H2>Time to find & <br />add some friends. </H2>
            <p>
              This area will be the place for your friends. If you already know who you’re looking for, just click “add friends” to the left and search using email or name.
            </p>
          </EmptyFriendsContainer>
        )}

      </FriendsList>
      {store.nonFriendsList.length > 0 &&  (
        <>
          <FriendsList>
            <FriendsStyledH2>the unknowns</FriendsStyledH2>
            <Subtitle>Here are all public profiles, click to read more about them. Find someone interesting? Send a friend request, it might be a future band member!</Subtitle>
            {store.nonFriendsList.map((user) => {
              return <Card key={user.id} user={user} type="non-friend" />
            })}
          </FriendsList>
          <Box  flex justifyContent="center" mt="2rem">
            {store.friendsLimitReach ? (
              <Subtitle textAlign="center">You reached the end!</Subtitle>
            ) : (
              <LoadMoreButton onClick={async () => await LoadMoreNonFriends() } disabled={loadingNonFriends}>
                {loadingNonFriends && <Spinner size={22} color="var(--ELK-White)" /> }
                Load more
              </LoadMoreButton>
            )}
          </Box>
        </>
      )
      }
    </>
  );
})

export default FriendsView